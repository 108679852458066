<template>
  <a-drawer
      :title="titleText + '提现明细'"
      placement="right"
      :width="'60vw'"
      :closable="true"
      :visible="visible"
      @close="onClose"
  >
    <span class="total-price" v-if="totalPrice">总金额：￥{{totalPrice}}</span>
    <div class="price-box mb-10">
      <span class="color-blue font-size-20 font-weight-bold">斗泉钱包余额：￥{{ dqPrice }}</span>
      <span class="color-green font-size-20 font-weight-bold ml-40">连连钱包余额：￥{{ lianLianPrice }}</span>
      <span class="color-red font-size-20 font-weight-bold ml-40">钱包总余额：￥{{ walletTotalPrice }}</span>
    </div>
    <div class="page-total-price mb-10">
      <span class="color-orange font-size-20 font-weight-bold">当前页面总金额：{{ pageTotalPrice }}</span>
      <span class="ml-40 color-orange font-size-20 font-weight-bold" v-if="foundTempTotalPrice">提现相同合计金额：{{ foundTempTotalPrice }}</span>
    </div>
    <div style="height: 80vh; overflow-y: scroll">
      <a-table
          @change="changePage"
          :pagination="pagination"
          :loading="loading"
          :columns="columns"
          :data-source="list"
          :rowKey="(record, index) => index"
      >
        <div slot="itemIdOrTimeSlot" slot-scope="row">
          <template v-if="row.type === 'income' && row.isAfterWithdrawal">
            <div class="color-green" v-if="row.id">{{ row.id }}</div>
            <div class="mt-10 color-green">{{ row.createTime }}</div>
          </template>
          <template v-else-if="row.type === 'income' && row.color">
            <div class="color-orange" v-if="row.id">{{ row.id }}</div>
            <div class="mt-10 color-orange">{{ row.createTime }}</div>
          </template>
          <template v-else>
            <div class="color-blue" v-if="row.id">{{ row.id }}</div>
            <div class="mt-10 color-gray">{{ row.createTime }}</div>
          </template>
        </div>
        <div slot="orderDetailSlot" slot-scope="row">
          <span v-if="row.type === 'income' && row.isAfterWithdrawal" style="color: green;cursor: pointer;" @click="handleGoToOrderDetail(row.orderNo)">{{ row.orderNo }}</span>
          <span v-else-if="row.type === 'income' && row.color" style="color: orange;cursor: pointer;" @click="handleGoToOrderDetail(row.orderNo)">{{ row.orderNo }}</span>
          <span v-else style="color: #2f54eb;cursor: pointer;" @click="handleGoToOrderDetail(row.orderNo)">{{ row.orderNo }}</span>
        </div>
<!--    提现，剩余等金额    -->
        <div slot="itemTypeSlot" slot-scope="row">
          <div v-if="row.type === 'income' && row.isAfterWithdrawal"><span style="color:green">{{row.type}}</span></div>
          <div v-else-if="row.type === 'income' && row.color"><span style="color:orange">{{row.type}}</span></div>
          <div v-else><span style="color:red">{{row.type}}</span></div>
        </div>
      </a-table>
    </div>
  </a-drawer>
</template>

<script>
import {convertStringToTimestamp} from "@/untils/timeAbout"

export default {
  data() {
    return {
      foundTempTotalPrice: 0,
      drawType: '',
      // 当前提现时间
      withDrawTime: '',
      // 提现金额
      withDrawPrice: 0,
      // 减剩余提现金额
      residueWithDrawPrice: 0,
      pageTotalPrice: 0,
      lianLianPrice: 0,
      dqPrice: 0,
      walletTotalPrice: 0,
      totalPrice: '',
      titleText: '',
      visible: false,
      list: [],
      loading: false,
      params: {
        withdrawalId: '',
        pageNum: 1,
        pageSize: 30,
      },
      pagination: {
        total: 0,
        current: 1,
        pageSize: 30,
        showTotal: (total) => `共${total}条`,
      },
      columns: [
        {
          align: "center",
          key: "time",
          title: "交易时间",
          width: 120,
          scopedSlots: { customRender: 'itemIdOrTimeSlot'}
        },
        {
          align: "center",
          title: `应收金额`,
          customRender: (text, row, index) => {
            return row.type === 'income' && row.isAfterWithdrawal
                ? <span style="color:green">￥{row.money}</span>
                : row.type === 'income' && row.color
                    ? <span style="color:orange">￥{row.money}</span>
                    : row.type === 'payout'
                        ? <span style="color:red">-￥{row.money}</span>
                        : <span style="color:red">￥{row.money}</span>;
          },
        },
        {
          align: "center",
          title: `备注`,
          customRender: (text, row, index) => {
            return row.type === 'income' && row.isAfterWithdrawal ? <span style="color:green">{row.remarks}</span> :
                row.type === 'income' && row.color ? <span style="color:red">{row.remarks}</span> : <span style="color:red">{row.remarks}</span>;
          },
        },
        {
          align: "center",
          title: "类型",
          scopedSlots: { customRender: 'itemTypeSlot'}
        },
        {
          align: "center",
          title: "查看订单",
          scopedSlots: { customRender: "orderDetailSlot" }
        }
      ],
    };
  },
  created() {
  },
  methods: {
    /** 去往订单详情 */
    handleGoToOrderDetail(orderNo) {
      const origin = location.origin
      const url = `${origin}/#/cms/dqplatform/newordermannage?orderNo=${orderNo}`;
      window.open(url)
    },
    async getList() {
      this.loading = true;
      const res = await this.axios(
          "/dq_admin/userWithdrawDetail/getWithdrawDetail",
          {
            params: this.params,
          }
      );
      this.loading = false;
      if (res.status !== '200') return (this.list = []);
      this.list = res.data.records
      let tempPageTotalPrice = 0
      for (let i = 0; i < this.list.length; i++) {
        if (this.list[i].type === 'withdraw') {
          break
        } else if (this.list[i].type === 'income') {
          tempPageTotalPrice += this.list[i].money
        } else if (this.list[i].type === 'payout') {
          tempPageTotalPrice -= this.list[i].money
        }
      }
      this.pageTotalPrice = parseFloat(tempPageTotalPrice.toFixed(2))

      // 计算
      if (this.params.pageNum === 1 && this.drawType !== 'productDraw') {
        const foundLastAfterWithDrawlIndex = this.list.findLastIndex(el => el.isAfterWithdrawal === 1) + 1
        const foundWithDrawIndex = this.list.findIndex(el => el.type === 'withdraw')
        console.log(foundLastAfterWithDrawlIndex, foundWithDrawIndex, '了解收到了见附件')
        if (foundLastAfterWithDrawlIndex < this.list.length) {
          if (foundLastAfterWithDrawlIndex !== foundWithDrawIndex) {
            if (foundWithDrawIndex > 0) {
              for (let i = foundLastAfterWithDrawlIndex; i < foundWithDrawIndex; i++) {
                this.residueWithDrawPrice = (this.residueWithDrawPrice * 10 + this.list[i].money * 10) / 10
              }
            } else {
              for (let i = foundLastAfterWithDrawlIndex; i < this.list.length; i++) {
                this.residueWithDrawPrice = (this.residueWithDrawPrice * 10 + this.list[i].money * 10) / 10
              }
            }
          }
        }
        if (this.residueWithDrawPrice === this.withDrawPrice) {
          if (foundLastAfterWithDrawlIndex < this.list.length) {
            if (foundWithDrawIndex > 0) {
              for (let i = foundLastAfterWithDrawlIndex; i <= foundWithDrawIndex; i++) {
                this.list[i].color = 'orange'
              }
              this.foundTempTotalPrice = this.residueWithDrawPrice
            } else {
              for (let i = foundLastAfterWithDrawlIndex; i < this.list.length; i++) {
                this.list[i].color = 'orange'
              }
              this.foundTempTotalPrice = this.residueWithDrawPrice
            }
          }
        }
      }
      this.pagination.total = res.data.total;
    },
    // 切换分页
    changePage(page) {
      const { current, pageSize } = page;
      this.pagination.current = current;
      this.params.pageNum = current;
      this.params.pageSize = pageSize;
      this.residueWithDrawPrice = 0
      this.getList();
    },
    async show(id, title, orderId, row) {
      this.foundTempTotalPrice = 0
      this.drawType = orderId ? 'productDraw' : 'otherDraw'
      this.withDrawPrice = row.money
      this.withDrawTime = convertStringToTimestamp(row.createTime)
      this.titleText = title
      this.columns[1].title = orderId ? `应收货款` : '金额'
      this.params.pageNum = 1
      this.params.withdrawalId = id
      await this.getWalletPrice(row)
      await this.getList()
      if (orderId) {
        await this.getTotalPrice()
      } else {
        this.totalPrice = ''
      }
      this.visible = true
    },
    /** 获取钱包余额 */
    async getWalletPrice(row) {
      const res = await this.axios('/dq_admin/userWithdrawDetail/getUserAccountOrLianlianAccount', {
        params: { userId: row.userId }
      })
      if (res.status !== '200') return
      if (res.data) {
        this.lianLianPrice = res.data.lianlianAccountMoney || 0
        this.dqPrice = res.data.douquanAccountMoney || 0
        this.walletTotalPrice = ((this.lianLianPrice * 10 + this.dqPrice * 10) / 10).toFixed(2)
        this.residueWithDrawPrice = 0
      }
    },
    async getTotalPrice() {
      const res = await this.axios("/dq_admin/userWithdrawDetail/getWithdrawalOrderTotalMoney", {
        params: {withdrawalId: this.params.withdrawalId}
      });
      if (res.status !== '200') return
      this.totalPrice = res.data.totalDrawalMoney
    },
    onClose() {
      this.pagination.current = 1
      this.visible = false;
      setTimeout(() => {
        this.$emit("close");
      }, 300);
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep {
  .ant-table-thead > tr > th,
  .ant-table-tbody > tr > td {
    height: 60px !important;
  }
}
.total-price {
  width: 100%;
  text-align: left;
  position: absolute;
  top: 10px;
  left: 300px;
  font-weight: bold;
  color: orangered;
  font-size: 20px;

}
.ml-40 {
  margin-left: 40px;
}
</style>